import { FC, useState } from 'react'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { UPDATE_ASSET_ITEM } from 'src/lib/queries/Settings/AssetLibrary/AssetLibrary'

import AssetLibraryItem from '../AssetLibraryItem/AssetLibraryItem'
import AssetLibraryToolbar from '../AssetLibraryToolbar/AssetLibraryToolbar'
import { Asset } from '../types'
interface AssetLibraryGridProps {
  assets: Asset[]
  refetch: () => Promise<unknown>
}

const AssetLibraryGrid: FC<AssetLibraryGridProps> = ({ assets, refetch }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  // UPDATE ASSET ITEM
  // Soft delete - update the isGlobal without destroying the reference object

  const [updateStorageObjectMutation] = useMutation(UPDATE_ASSET_ITEM, {
    onCompleted: async () => {
      await refetch()
      setIsDeleting(false)
    },
    onError: (error) => {
      setIsDeleting(false)
      toast.error(error.message, {
        duration: 5000,
      })
    },
  })

  function updateAssetItem(id: number) {
    // Start Update
    setIsDeleting(true)

    // Update card by ID
    updateStorageObjectMutation({
      variables: {
        id: id,
        input: {
          isGlobal: false,
        },
      },
    })
  }

  return (
    <div>
      <div className="p-5 bg-white">
        <AssetLibraryToolbar refetch={refetch} />

        {!assets.length ? (
          <p className="text-gray-400 p-10 text-center w-full">
            No Assets found.
          </p>
        ) : (
          <div
            className="grid gap-4 w-full"
            style={{
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
              gridTemplateRows: 'repeat(auto-fill, minmax(80px,auto))',
            }}
          >
            {assets.map((asset, index) => (
              <AssetLibraryItem
                key={index}
                asset={asset}
                deleteAssetItem={updateAssetItem}
                isDeleting={isDeleting}
              />
            ))}
          </div>
        )}
        {isDeleting && (
          <div className="bg-gray-100 opacity-50 absolute w-full h-full top-0 left-0 z-10"></div>
        )}
      </div>
    </div>
  )
}

export default AssetLibraryGrid
