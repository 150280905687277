import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { Metadata } from '@redwoodjs/web'

import AssetLibraryCell from 'src/components/AssetLibrary/AssetLibraryCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const AssetLibraryPage = () => {
  return (
    <>
      <Metadata title="Asset Library" description="Asset Library" />
      <PageHeader title="Asset Library">
        <p className="flex items-center gap-2 p-2 text-xs rounded bg-amber-200">
          <ExclamationTriangleIcon className="h-4 w-4 mt-0.5" />
          <span>
            Asset Library is not available for all features. Any icons uploaded
            here will only appear as options for home links.
          </span>
        </p>
      </PageHeader>
      <AssetLibraryCell fileNames={['asset-library-icon']} />
    </>
  )
}

export default AssetLibraryPage
