export const DELETE_ASSET_ITEM = gql`
  mutation DeleteAssetItem($id: Int!) {
    deleteStorageObject(id: $id) {
      id
    }
  }
`

export const UPDATE_ASSET_ITEM = gql`
  mutation UpdateAssetItem($id: Int!, $input: UpdateStorageObjectInput!) {
    updateStorageObject(id: $id, input: $input) {
      id
      downloadUrl
      isGlobal
    }
  }
`
